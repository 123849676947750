html {
  height: 100%;
}

body {
  height: 100%;
  background-image: url(panic.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #464646;
}
